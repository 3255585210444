<template>
  <div class="content-full">
    <div>
      <h3 class="dark-grey">Step 2: Terms And Conditions</h3>
      <p class="body pb-3">Review the terms and conditions and confirm your agreement.</p>
    </div>

    <Notification v-if="investingWith === 'Aegon'" id="invest-now-notice" type="warn" icon="warning" title="Please Note"
                  html="If you have clarity managed holdings with Aegon and cannot see them, please do not
                  continue and contact clarity on: <strong>0800 368 7511.</strong>" />

    <div id="add-investment-terms" class="add-investment-section">
      <h3 class="dark-grey">Agreement</h3>
      <p class="body">
        Thank you for choosing to invest through our website. We make this facility available on the clear understanding
        that we are acting at your explicit request and have not made any recommendation as to the suitability of the
        contract for your particular needs. We assume that you have read and understood the explanation of this product
        and of the investment risks involved. I would therefore be grateful if you could mark the tick box below to
        confirm
        that you have not requested, nor received any advice from us. Please refer to the Terms of Business listed below
        for details of any cancellations rights you will have relating to this investment. The costs of investment will
        differ depending on the fund you have chosen. The details of costs and discounts applicable to our Buy List Funds
        are given on the Investment Buy List page. All investments will quote an Initial Charge and an Annual Management
        Charge. clarity will always discount any portion of the Initial Charge that is Initial Commission, and will
        often
        negotiate a further discount on any remaining Initial Charge. clarity’s default ongoing charge for investing is
        0.45% pa. However, should you have already agreed an ongoing investment management service with clarity
        (including
        annual or quarterly rebalancing), this fee will be applied instead. Prior to confirming your transaction, the
        exact charges relating to your investment will be displayed. The charges relating to non Buy List Funds can be
        viewed in advance on the relevant fund provider’s fact sheet available on their respective websites or can be
        provided on request. Before proceeding to the online application, please take the time to read and understand
        the following material regarding the service, who clarity are and how they operate. By ticking the box below you
        are confirming also that you have read and understood these documents.
      </p>
      <div class="button-cta">
        <a
            href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/claritytermsofbusiness.pdf"
            target="_blank">
          <Button class="clarity-btn clarity-gradient-grey">
            <Icon name="pdf-icon" class="mr-3"/>
            Terms of Business
          </Button>
        </a>
      </div>
      <div>
        <Checkbox inputId="terms" name="terms" v-model="terms" :binary="true"/>
        <label :for="terms" class="ml-3 body">I confirm that I agree to the above statement and have read and understood the
          accompanying material.</label>
      </div>
    </div>
    <div id="add-investment-aml" class="add-investment-section">
        <h3 class="dark-grey">Anti-Money Laundering Requirements</h3>
        <p class="body">You should be aware of the Anti-Money Laundering Regulations which apply to all transactions.
          For more details, please download our Anti Money Laundering Checklist.</p>
      <div class="button-cta">
        <a
            :href="clarityURL+'/sites/default/files/compliance/moneylaundering.pdf'"
            target="_blank">
          <Button class="clarity-btn clarity-gradient-grey">
            <Icon name="pdf-icon" class="mr-3"/>
            Anti-Money Laundering Checklist
          </Button>
        </a>
      </div>
      <p class="body" style="padding: 0 0 30px 0">Recognising that some of our clients may be reluctant to send documents of this nature
        through the post, we have a facility that will allow us to obtain independent identity verification from a
        third party without the need for us to see original documents. Smart Search offers a service specifically
        designed to meet AML requirements in line with the Sanctions and Anti-Money Laundering Act 2018 by generating
        a report verifying the identity of an individual based on records held with Equifax, Experian and Dow Jones.
        This report leaves a neutral footprint on your credit report so will not affect your credit rating. If you are
        happy for us to run this check, should it be required, please tick the following box. If you do not tick the
        box we may contact you to request 2 of the documents included in the above Checklist.</p>
      <div>
        <Checkbox inputId="aml" name="aml" v-model="aml" :binary="true"/>
        <label :for="aml" class="ml-3 body">Run the SmartSearch Anti-Money Laundering check as
          described above.</label>
      </div>
    </div>
    <div id="add-investment-risk" class="add-investment-section">
      <h3 class="dark-grey">Risk Warning</h3>
      <p class="body">You should note that the value of investments and the income from them may go down as well as
        up
        and is not guaranteed. You may not get back the full amount invested. Past performance is not necessarily a
        guide to future performance. Investments in overseas assets carry an exchange rate risk and may cause the value
        of the investment to fluctuate. The above comments are based on current legislation which may be subject to
        change.</p>
    </div>
    <div class="flex justify-content-between">
      <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/add/provider')"><span class="material-icons-round grey">chevron_left</span>Go
        Back
      </Button>
      <Button class="clarity-btn clarity-gradient-orange" :disabled="isDisabled" @click="$router.push('/investments/add/complete'); confirmStep()">Confirm and Invest online with {{ investingWith }}</Button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon.vue'
import {ref, computed, onMounted} from 'vue'
import Notification from "@/components/common/Notification";
import {useStore} from "vuex";

export default {
  name: "StepTermsConditions",
  components: {Notification, Icon},
  setup() {
    const aml = ref(false)
    const terms = ref(false)
    const store = useStore()
    const clarityURL = process.env.VUE_APP_CLARITYURL;

    const isDisabled = computed(() => {
      return aml.value === false || terms.value === false
    })

    const confirmStep = () => {
      store.dispatch('setSelectedStep', {stepper: 'investnow', step: 3, completed: 2})
    }

    onMounted(() => { store.dispatch('getPolicyOptions') });

    return {
      aml,
      terms,
      isDisabled,
      investingWith: computed(() => store.getters.getInvestWithProviderName),
      confirmStep,
      clarityURL
    }
  }
}
</script>

<style scoped lang="scss">
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.add-investment-section {
  padding: 30px 0;

  p {
    padding-top: 20px;
    margin: unset;
  }
}
.button-cta {
  padding: 30px 0;
}
button {
  padding: 15px 45px;
}
.body {
  font-size: 18px;
  line-height: 28px;
}

</style>
